<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkInputText from "@components/input/AkInputText";
import BilanCircuit from "@views/bilan/circuit/components/BilanCircuit";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCircuitService from "@services/bilanCircuitService";
import circuitService from "@services/circuitService";

export default {
  components: {AkFormView, AkInputText, BilanCircuit},
  mixins: [randomRef, roleMixin],
  metaInfo() {
    return {
      title: "bilan.details"
    }
  },
  data() {
    return {
      loading: false,
      circuitList: [],
      current: {},
      currentGeneral: {}
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();
  
    let msg = this.$route.query.msg;
    if (msg && msg !== "") {
      this.getRef().success(msg);
    }

    let p1 = bilanCircuitService.findByCeiId(this.$route.params.id);
    p1.then(data => this.current = data);
  
    let p2 = circuitService.findByCeiId(this.$route.params.id);
    p2.then(data => this.circuitList = data);

    Promise.all([p1, p2]).then(()=>{
      this.initData();
      this.getRef().hideLoader();
    });
  },
  methods: {
    initData() {
      this.currentGeneral.ager = this.circuitList[0].agerLabel;
      this.currentGeneral.uer = this.circuitList[0].uerLabel;
      this.currentGeneral.cei = this.circuitList[0].ceiLabel;
      this.currentGeneral.ceiId = this.circuitList[0].ceiId;
      this.currentGeneral.year = this.circuitList[0].year;
      for (const element of this.current) {
        for (const circuit of this.circuitList) {
          if (element.circuitId === circuit.id) {
            element.number = circuit.number;
            element.axis = circuit.axis;
          }
        }
      }
      // retirer les current qui ont une valeur dans year (ce sont les anciennes données qui sont archivées)
      this.current = this.current.filter(c => c.year === null);
      this.current.sort((a,b) => {
        if (a.number < b.number) return -1;
        else return 1;
      });
      this.loading = false;
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.details')">
    <template v-slot:action>
      <RouterLink v-if="currentGeneral.ceiId !== undefined && this.canEditCircuit() && !currentGeneral.year && this.canEditOrDeleteReview(currentGeneral)"
                  :to="{ name: 'bilanCircuitUpdate', params: {id: currentGeneral.ceiId}}"
                  class="btn btn-inverse-primary btn-xs">
        <i class="fe fe-edit pr-1"/>{{ $t('update') }}
      </RouterLink>
    </template>
    <template v-slot:form>
      <div class="form-row">
        <AkInputText
          :disabled=true
          v-model=currentGeneral.ager
          class-name="col-md-4"
          :label="$t('ager_label')"/>
        <AkInputText
          :disabled=true
          v-model=currentGeneral.uer
          class-name="col-md-4"
          :label="$t('uer_label')"/>
        <AkInputText
          :disabled=true
          v-model=currentGeneral.cei
          class-name="col-md-4"
          :label="$t('cei_label')"/>
      </div>
      <div class="mt-2 row">
        <BilanCircuit v-for="(row, index) in current" :key="index" :row="row" :index="index" ref="panels" :disabled="true"/>
      </div>
    </template>
  </AkFormView>
</template>